import React, { useState, useEffect } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findImages } from 'utils/utils';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import Modal from 'common/Modal/DepricatedModal/Modal';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import axios from 'axios';


import './SectionImageGallery.scss';

interface Props {
  content: string;
  title: string;
  page: string;
}

export function SectionImageGallery({ content, title }: Props): React.ReactElement {
  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>([]);
  const [galleryIsOpen, setGalleryIsOpen] = useState<boolean>(false);
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
  const fetchedImages:ReactImageGalleryItem[] = [];

  useEffect(() => {
    const imagesInContent = findImages(content);
    fetchImages(imagesInContent);
  }, []);

  function printImageGalleryButton() {
    if (images.length > 0)
      return (
        <LightTooltip
          title={'Click to view images in gallery view'}
          data-testid={'image-gallery-tooltip'}
          placement="top-start"
          arrow
        >
          <button
            data-testid="gallery-view"
            aria-label="enlarge image"
            className="gallery-view-button"
            onClick={() => {
              setGalleryIsOpen(true);
              setGalleryImages(images);
            }}
          >
            <FontAwesomeIcon icon={'image' as IconName} />
          </button>
        </LightTooltip>
      );
  }

  const fetchImages = async(imagesInContent:ReactImageGalleryItem[])  => {
      for (const endpoint of imagesInContent) {
        await axios.get(endpoint.original, {
          responseType: 'blob',
          headers: {
            'Accept': 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8'
          }})
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          fetchedImages.push({
            original: url,
            thumbnail: url,
          });
        }).catch (() =>  
          console.log("error")
      );
    }
    setImages(fetchedImages);
  };

  return (
    <>
      {galleryIsOpen && (
        <Modal
          title={title}
          isOpenModal={galleryIsOpen}
          closeHandler={() => setGalleryIsOpen(false)}
          showCloseIcon={true}
          className="gallery-modal"
        >
          <ImageGallery
            items={galleryImages}
            showPlayButton={false}
            showThumbnails={false}
            showBullets={true}
          />
        </Modal>
      )}
      {printImageGalleryButton()}
    </>
  );
}
