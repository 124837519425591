import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import RichTextInputBox from 'common/RichTextInputBox/RichTextInputBox';
import 'react-image-gallery/styles/css/image-gallery.css';
import { SectionImageGallery } from 'common/SectionImageGallery/SectionImageGallery';
import { useRecoilState } from 'recoil';
import IsContentChangedState from 'state/ContentChangedState';
import ConfirmationModal from 'common/ConfirmationModal/DepricatedConfirmationModal/ConfirmationModal';
import EditPencil from 'common/EditPencil/EditPencil';

interface Props {
  content: string;
  title: string;
  field: string;
  updateOverviewSection: (title: string, content: string) => Promise<void>;
  isOnDesktopIsEditorOrAdmin: boolean;
}

export default function ContentBox({
  content,
  field,
  title,
  updateOverviewSection,
  isOnDesktopIsEditorOrAdmin,
}: Props): React.ReactElement {
  const [newContent, setNewContent] = useState<string>(content);
  const [inEditState, setInEditState] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isContentChanged, setIsContentChanged] = useRecoilState(IsContentChangedState);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    setNewContent(content);
    setInEditState(false);

    return () => {
      setInEditState(false);
    };
  }, [content]);

  function setNewContentAndChangedFlag(contentToSet: string) {
    setNewContent(contentToSet);
  }

  function printViewing() {
    return (
      <>
        <div>
          <h3 className={'heading-with-pencil caps-header'}>
            {title}
            {isOnDesktopIsEditorOrAdmin && (
              <EditPencil
                onClick={() => {
                  setInEditState(true);
                }}
                dataTestID={`${field}-edit-pencil`}
                ariaLabel={'edit section'}
              />
            )}
          </h3>

          <SectionImageGallery content={content} title={title} page="overview" />
        </div>
        <p
          data-testid={`${field}-content`}
          className={'content'}
          dangerouslySetInnerHTML={{ __html: newContent }}
        ></p>
      </>
    );
  }

  function printEditing() {
    function doCancel() {
      setNewContent(content);
      setInEditState(false);
      setIsContentChanged(false);
      setShowConfirmationModal(false);
    }

    return (
      <>
        {showConfirmationModal && (
          <ConfirmationModal
            showModal={showConfirmationModal}
            setShowModal={setShowConfirmationModal}
            exitWithoutSaving={doCancel}
          />
        )}
        <h1 className={'caps-header'}>{title}</h1>
        <button
          data-testid="cancel"
          aria-label="cancel edit overview"
          className="cancel-icon"
          onClick={() => {
            if (isContentChanged) {
              setShowConfirmationModal(true);
            } else {
              doCancel();
            }
          }}
        >
          <FontAwesomeIcon icon={'times' as IconName} />
        </button>
        <RichTextInputBox
          setDisabled={setDisabled}
          id={field}
          setSection={(contentToSet) => setNewContentAndChangedFlag(contentToSet)}
          content={newContent}
          title={field}
        />
        <button
          className={`save button-blue ${disabled ? 'disabled' : ''}`}
          disabled={disabled}
          title={`${disabled ? 'Saving Image(s) is in progress. Please Wait.' : ''}`}
          onClick={() =>
            updateOverviewSection(title, newContent).then(
              () => setInEditState(false),
              () => setInEditState(true),
            )
          }
        >
          Save
        </button>
      </>
    );
  }

  return (
    <section className={'content-box'}>
      {!inEditState && printViewing()}
      {isOnDesktopIsEditorOrAdmin && inEditState && printEditing()}
    </section>
  );
}
